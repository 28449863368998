<template>
  <div>
    <topbar
      :title="
        $store.state.language == 1 ? '條款及細則' : 'Terms and Conditions'
      "
    ></topbar>
    <div class="agreement" v-if="$store.state.language == 1">
      <p>肯德基會員計劃使用條款及細則 (「本條款」)</p>
      <h4>1. 一般條款</h4>
      <p>
        肯德基會員計劃（「本計劃」或「會籍」）乃由怡和有限公司肯德基部門及Birdland
        (Hong Kong)
        Limited）（以下統稱「KFC」、「肯德基」，「我們」及或「我們的」）管理及營辦運。
        <br />
        <br />
        本計劃內有包含積分獎賞的累積及保留制度，積分乃本計劃積分獎賞的衡量單位。
        <br />
        <br />
        只有已經透過我們的肯德基香港網站、肯德基香港手機應用程式(「肯德基手機程式」)或Storellet手機應用程式（另見下文第1.4
        段）(「Storellet手機程式」)（兩者都可在App StoreGoogle Play 或 App Store
        Google Play
        Store下載）完成會員登記程序的人士客戶才有資格成為本計劃的會員及享用會員優惠（稱「會員」，「會籍」）。
        <br />
        <br />
        Storellet手機程式是由Crozz
        Limited所擁有和經營。利用Storellet這個平台，協助用戶可以有效地管理將自己喜愛餐廳的手機程式（包括肯德基手機程式）及會員積分及優惠存放於單一的Storellet
        App。使用Storellet手機程式需要登記為Storellet成為會員，而Storellet
        App的會員登記和使用是受到Storellet本身自己的條款和細則條件以及私隱私政策所拘約束。
        <br />
        <br />
        會員可透過我們肯德基香港的網站、肯德基手機程式或Storellet手機程式登入自己的肯德基會員帳戶。如會員於Storellet手機程式有任何會員活動而造成積分獎賞結餘或優惠券記錄有更改，該積分獎賞結餘或優惠券的更改將同時反映在該會員的肯德基手機程式及我們的網站中，反之亦然。換句話說，會員於我們的網站Storellet手機程式、肯德基手機程式或Storellet手機程式我們的網站中曾賺取積分或曾用或兌換積分來兌換優惠券都會反映在同一個肯德基會員帳戶中。
        <br />
        <br />
        本計劃適用於十八歲或以上人士。如申請人閣下的年齡介乎十二歲或以上至十七八歲以下而希望成為會員，申請人閣下需於完成登記前得到父母或監護人的同意。
        <br />
        <br />
        會員須向肯德基員工出示肯德基手機程式内或肯德基網站內會員資料頁的會員號碼條碼（稱「會員條碼」）並對準櫃檯前的掃描器以掃描確認會籍。如使用我們的肯德基香港網站，必須於完成交易前登入，以確認會籍。
        <br />
        <br />
        使用肯德基優惠券（另見下文第3.2段）、自拍優惠券（另見下文第3.3段）、和團購換領券（另見下文第3.4段）:
        均以會員條碼識別。如使用這些優惠券/換領券，會員落單時必須向店員顯出示使用的優惠券/換領券條碼並對準櫃檯前的掃描器以作掃描。如在使用我們的網站使用這些優惠券/換領券落單，必須於完成交易前登入，並選取相關優惠券/換領券。
        <br />
        <br />
        不當或濫用本計劃、積分獎賞或其任何優惠，可導致終止會籍或撤銷優惠。
        <br />
        <br />
        肯德基保留權利：隨時更改本計劃的結構、優惠及其他特色（包括本條款），或者隨時終止本計劃的權利。會員有責任留意本計劃優惠、條款及細則的事宜，包括本條款的更新。會員一經參與使用本計劃/使用積分獎賞或其任何會員優惠，將被視為同意並接納任何本計劃優惠、條款及細則更新或本條款的更改。肯德基不會為由此造成的失誤損失或損害負上責任。
        <br />
        <br />
        任何因網絡問題、系統固障、電話接收問題或被第三方應用程式攔截而導致肯德基手機程式及其功能和服務受影響或不可使用的問題，肯德基均不會承擔任何責任。
        <br />
        <br />
        肯德基有權暫停我們肯德基的手機程式及其功能和服務/或其任何部份，以作維修、保養、更新或其他我們認為適合或需要的工作，而毋須向會員作事前通知。
        <br />
        <br />
        每個肯德基會員戶口內的積分結餘或記錄應以我們持有的記錄為準及為最終記錄。如有任何爭議，我們對所有會籍、積分獎賞、特別優惠/折扣及優惠券一切事宜保留最終決定權。
      </p>
      <h4>2. 會員籍申請/登記</h4>
      <p>
        顧客可透過我們肯德基香港的網站、肯德基手機程式或Storellet手機程式(另見上文第1.3至1.4
        段)完成登記程序，以申請/登記成為會員。
        <br />
        <br />
        顧客成功申請/登記後會在其指定手機收到手機短訊確認碼
        (SMS)，顧客必須輸入確認碼方為以完成整個會員申請/登記程序成為會員。
        <br />
        <br />
        顧客加入肯德基會員計劃會籍的資格，由肯德基全權決定，而肯德基有權拒絕任何申請人之申請或在任何時候終止任何會員會籍
      </p>
      <h4>3. 會員優惠</h4>
      <h5>3.1 賺取積分獎賞</h5>
      <p class="secondCon">
        a)
        會員之本人須於交易完成前向店員顯出示會員條碼並對準櫃檯前的掃描器以作掃描，以在交易中獲取積分。如使用我們肯德基香港的網站，必須於完成交易前登入，以獲取積分。若會員於交易前未能出示本人的會員條碼或只提供手機號碼及/或會員號碼，則不能獲取積分。任何情況下，積分不會隨後及/或於交易後補發。
        <br />
        <br />
        b) 會員在任何肯德基餐廳或商舖每惠顧HK$1(經折扣後，如有)均可獲取1分
        (不連小數點計算)。，例如: 消費$36.6=賺取36分) 。
        所有積分必須為會員本人消費所獲得。
        <br />
        <br />
        c) 只有在肯德基香港的網站及香港之肯德基餐廳/商舖
        (亞洲國際博覽館、馬場及大球場及馬場分店除外)
        及我們的網站上的堂食及外賣自取的消費方可賺取積分，不適用於外賣速遞及任何團購活動。
        <br />
        <br />
        d)
        小費、公司折扣及特別優惠、獎品賞兌換、捐款、經肯德基香港網站的外賣速遞、生日派對、購買現金券及任何團購換領券(見下文第3.4段)活動均不獲給予積分。
        <br />
        <br />
        e)
        會員每消費日最多獲取積分的次數上限為3次；一星期內獲取積分的次數上限為10
        次。
        <br />
        <br />
        f) 每次交易只可賺取一次積分。
        <br />
        <br />

        g)
        積分有效期為至會員籍登記日12個月後的下1個季度完結日，季度完結日為每年1月31日；4月30日；
        7月31日；10月31日 (例如:
        會籍第1次登記日期為2015年2月8日，所有積分會於2016年4月30日到期) 。
        <br />
        <br />
        h)
        會員須自行檢查自己的積分有效期。逾期之積分將於到期日後被全數清除。如有任何爭議，一律以肯德基系統內會員帳戶的積分結餘的資料作準及一概不會作補分程序。
        <br />
        <br />
        i)
        如果肯德基懷疑積分是透過欺詐或不誠實的手段途徑獲得，肯德基保留權利從其會員帳戶中扣除積分。
      </p>
      <h5>3.2 積分兌換及肯德基優惠券</h5>
      <p class="secondCon">
        a) 賺取積分賺取及積分兌換只適用限於積分有效期內有效（另見上文第3.1g
        段）。逾期未使用的之積分並不能延期，並將逾期作廢全數清除。
        <br />
        <br />
        b)
        積分可用來兌換肯德基優惠券。會員需經肯德基香港的網站、肯德基手機程式或Storellet手機程式登入自己的肯德基會員帳戶，進入著數優惠「肯德基優惠券」，點擊意向的肯德基優惠券及按指示完成兌換。成功兌換後，肯德基優惠券將儲存在「我的優惠券」。
        <br />
        <br />
        c) 每張肯德基優惠券每人只限用一次。
        <br />
        <br />
        d)
        積分兌換均取決於肯德基是否接納，及會員帳戶內累積的積分是否足夠及肯德基是否接納。積分如果不足，換領要求將會自動被拒絶取消。
        <br />
        <br />
        e)
        積分兌換一經確定成功後不可更改或取消，相關之積分數量亦將隨交易從閣下的積分獎賞戶口中自動被扣除。
        <br />
        <br />
        f)
        每張肯德基優惠券均有使用期限，並將於在肯德基換領券內列明之日到期。有使用時間和日期限制。優惠券逾期作廢，未使用或逾期之優惠券將被作廢，於任何情況下均並不設退款延期及退款延期。
        <br />
        <br />
        g)
        肯德基優惠券使用受肯德基換領券內有關條款及細則約束。會員需根據優惠券內列明的條款及細則方可使用。
      </p>
      <h5>3.3 自拍優惠券</h5>
      <p class="secondCon">
        a)
        會員需在自拍優惠活動期間透過肯德基手機程式或Storellet手機程式登入自己的帳戶，進入點擊“「自拍優惠券”
        」及按指示完成指定任務，包括拍照及提交照片（「自拍照」）並分享至個人Facebook。
        <br />
        <br />
        b)
        如成功完成指定任務即可得到自拍優惠券，自拍優惠券會出現自動儲存於肯德基手機程式「我的優惠券」內。
        <br />
        <br />
        c)
        會員於每次可提交自拍照的次數將因應有關的自拍優惠活動期間只可參加及/或獲得該次優惠而有所不同活動限定的參加及優惠券次數。請留意每次優惠活動的條款及細則和條件。
        <br />
        <br />
        d)
        會員一經上傳自拍照片及/或參加自拍優惠活動（稱「參加者」），即表示參加者同意及接受本計劃的條款及細則及我們的私隱政策。
        <br />
        <br />
        e)
        參加者會員一經上傳自拍照片或參加自拍優惠活動，即表示同意並授予肯德基不可撤銷、不可撤回及永久的專用的許可以使用、編輯、複製、列印、複印及刋登已提交的自拍照及使用自拍照作公開使用及展示之用途、陳列、宣傳推廣之用途，而肯德基無須事先徵得會員的同意或提供任何彌償費用。
        <br />
        <br />
        f)
        參加者會員聲明及承諾保證為上傳自拍照片的唯一版權擁有人，並據自己所知沒有任何第三方會對該上傳照片有任何投訴或申索。
        <br />
        <br />
        g)
        參加者會員聲明及承諾保證上傳自拍照片為自己親自拍攝製作之原創作品，並無侵犯他人的知識產權或任何其他權益，(包括但不限於版權)且從未以任何形式於媒體、或公開場合公眾場合或其他比賽展示或發表。
        <br />
        <br />
        h)
        如參加者會員所上傳的自拍照內容或照片含侮辱、譭謗、不雅、濫用、誤導內容或其他不當內容等，肯德基保留在不作預先通知而的情況下移除上傳自拍照的內容或照片的權利。
        <br />
        <br />
        i)
        如參加者會員所上傳的內容或照片濫用或自拍照侵犯或違反他人權益，包括但不限於知識產權等，肯德基明確表示不承擔任何法律責任。
        <br />
        <br />
        j)
        如參加者會員同意彌償肯德基因會員關於上傳自拍照而違反本條款，參加者須對違反條款所導致肯德基直接或間接遭受的一切合理損失、責任及費用或損害全面及有效地作出彌償，包括但不限於對第三方知識產權的侵犯。
        <br />
        <br />
        k)
        每張自拍優惠券均有使用期限，並將在自拍優惠券列明之日到期。未使用或逾期的自拍優惠券將被作廢，不設任何延期。有使用時間和日期限制。優惠券逾期作廢，逾期之優惠券於任何情況下均不設延期及退款。
        <br />
        <br />
        l)
        自拍優惠券的使用受自拍優惠券內有關條款及細則約束。會員需根據優惠券內列明的條款及細則方可使用。
        <br />
        <br />
        m)
        肯德基有權暫停自拍優惠券之服務或其任何部份，以作維修、保養、更新或其他肯德基認為適合的工作，而毋須向會員作事前通知。
      </p>
      <h5>3.4 為食團購換領券</h5>
      <p class="secondCon">
        a)
        會員如欲購買團購換領券，需透過肯德基手機程式或Storellet手機程式登入自己的帳戶，進入「為食肯德基團購」，點擊意向的團購換領券及按指示完成購買程序，。即可得到團購換領券，一經成功購買，團購換領券會出現於自動儲存在會員肯德基手機程式「我的優惠券」內。
        <br />
        <br />
        b) 團購換領券售出後一概不接受任何理由或情況下得退款或退換。
        <br />
        <br />
        c) 團購換領券只適用於堂食或外賣自取，不適用於外賣速遞服務。
        <br />
        <br />
        d)
        每張團購換領券均有使用期限，並將在團購換領券列明之日到期。有使用時間和日期限制。會員必須於團購換領券有效期內兌換套餐。換領券逾期作廢，逾期之換領券於任何情況下均不設延期及退款。
        <br />
        <br />
        e)
        團購換領券的使用受在團購換領券內有關條款及細則約束。未使用或逾期的團購換領券將被作廢，不設任何退款或延期。會員需根據優惠券內列明的條款及細則方可使用。
        <br />
        <br />
        f)
        團購換領券必須依購買時所選的肯德基餐廳/分店換領，所選的肯德基餐廳/分店於交易/團購換領券購買完成後不得更改。
        <br />
        <br />
        g) 所有團購換領券的交易不會將不能賺取積分。
        <br />
        <br />
        h) 團購換領券不得兌換現金。
        <br />
        <br />
        i) 團購換領券不能與其他推廣、特別優惠或折扣同時使用。
        <br />
        <br />
        j)
        團購換領券套餐指定食品不得更改，除了但當套餐指定食品短缺售罄，在指定肯德基餐廳/分店的店員可視乎食品供應情況以同等或相近價錢食品代替。
        <br />
        <br />
        k)
        肯德基有權暫停為食團購優惠券之服務或其任何部份，以作維修、保養、更新或其他肯德基認為適合的工作，而毋須向會員作事前通知
      </p>
      <h4>4. 會籍終止</h4>
      <p class="secondCon">
        4.1
        會員如欲終止其會籍，可隨時以書面通知肯德基，屆時任何未使用兌換的積分將予被註銷/作廢。
        <br />
        <br />
        4.2
        如發現任何會員有不當行為、欺詐、誤用積分或不依會遵守本計劃條款及細則，及/或其他相關條款及細則，均可導致個人會籍被即時被終止和所有積分被註銷，不論有否事前通知。肯德基保留權利：在不論有否通知的情況下終止任何人的會籍，屆時所有會員優惠及權利將予註銷。
      </p>
      <h4>5. 私隱</h4>
      <p>
        關於我們使用及收集顧客和會員的個人資料，請參考閱我們的「私隱政策」以知悉我們可能向會員收集之資料及怎樣使用該等資料。
      </p>
      <h4>6. 其他事項</h4>
      <p class="secondCon">
        6.1
        肯德基保留權利：隨時更改本條款及任何在肯德基餐廳/分店、網站及在線服務提供的任何資訊、服務和資源，包括kfchk.com網站、肯德基手機程式，而無須事前通知。本條款最新的版本可見於肯德基網站及肯德基手機程式。我們分店，網站及/或手機程式及/或平板電腦應用程式及/或任何於社交媒體或其他網站的應用程式所提供的資訊、服務和資源，以及本條款。本條款是由香港法律管轄，並按香港法律詮釋，雙各方同意服從香港法院的專有屬審判權。若因任何理由，法院認為本條款的任何條款或部分條款不能強制執行，其餘條款仍會繼續全面有效。任何一方寬免本條款其中任何條款，必須以書面簽認，才會有效。
        <br />
        <br />
        6.2
        肯德基有絕對權利及酌情權於任何時候終止有關本計劃之優惠及的運作，而無須向會員發出事前通告或給與任何理由。本計劃之優惠及運作被終止以後，會員之一切權利及優惠即告終止。於任何情況下，會員不得亦不能因本計劃之優惠及的運作終止而向肯德基提出任何性質或在任何情況下引致之申索償或要求。
        <br />
        <br />
        6.3 如本條款的中、英文版本發生歧異，概以英文版本為準。
      </p>
      <h4>7. 外賣速遞</h4>
      <p class="secondCon">
        7.1 肯德基外賣速遞服務時間:星期一至星期日（包括公眾假期）：
        上午11時至晚上11時正。
        <br />
        <br />
        7.2
        外賣速遞限於指定服務範圍，詳情可按送貨地點搜尋向分店職員查詢或請致電熱線(218
        00000)及向分店職員查詢。如送貨地點位於沒有升降機服務之大廈/村屋，顧客須到大廈地面或指定地點取貨。
        <br />
        <br />
        7.3 訂購滿港幣 $90
        或以上可享免費送遞服務一次(只限同一地址)。港幣$90以下的訂單如需送遞將以收取港幣$90計算。
        <br />
        <br />
        7.4 食品供應視乎個別餐廳而異。餐單及價格如有更改，恕不另行通知。
        <br />
        <br />
        7.5 速遞員將會準備只會攜帶少量零錢以供找續。
        <br />
        <br />
        7.6 不接受銀聯卡或JCB卡結賬及，該等卡亦不享用有任何優惠。
        <br />
        <br />
        7.7
        如需賺取積分或兌換獎賞，KFC樂脆會會員積分或兌換獎賞，必須於交易前以會員登入。只適用於網上訂購，受會員計劃使用條款及細則約束。
        <br />
        <br />
        7.8 如因機件故障技術問題或網站被蓄意破壞而引致任何爭議價錢有誤，
        有關爭議Birdland (Hong Kong) Limited保留最終決定權。
        <br />
        <br />
        7.9 如有任何異議，Birdland (Hong Kong) Limited 保留最終決定權。
      </p>
      <h4>8. 退貨或退款</h4>
      <p>
        並非所有登於本網站的產品圖片僅供參考。均會與產品完全相符，你顧客不得單因產品與其網上圖片不盡相符為理由而有權拒收要求退款。
        <br />
        <br />
        我們會盡致力確保正確完成訂單，並按下列方式處理如有任何歧異，。你顧客須於產品交貨後一小時內致電製作產品的分店或致電我們的顧客服務熱線21800000，或於通過本網站“聯絡我們”頁面提交電郵，將相關歧異未感滿意的送遞報通知我們：
        <br />
        <br />
        若任何產品於交貨時証實已經損壞，或已訂購的產品未獲送遞，或若所交貨品並不包括你訂購及已收費的產品，或若誤送另一產品，香港肯德基會按你的選擇，向你退還該產品的同等價值或重新交付該產品，不另收費。
        <br />
        <br />
        倘若不在你訂單之內的任何產品誤送給你，香港肯德基保留收回誤送產品的權利，不另收費。
        <br />
        <br />
        提交退款請求的顧客人需提供：退款理據貨原因，訂單號碼，用於訂單的電話號碼，訂單日期以及付款方式（所用信用卡賬號或支付寶訂單號等）。退貨金額會在申請通過後6-8個星期退返至顧客人用作的付款之戶口(現金則會以支票退還)。
      </p>
      <h4>9. Yuu 積分</h4>
      <p class="secondCon">
        9.1 1.
        KFC肯德基會員及Yuu會員於惠顧時，可選擇賺取肯德基KFC積分或Yuu積分。
        <br />
        <br />
        9.2 2. 電話訂購不可賺取及兌換Yuu積分。
        <br />
        <br />
        9.3 3. 網上訂購不可兌換Yuu積分。
        <br />
        <br />
        9.4 4. Yuu積分只限於分店兌換Yuu積分。
        <br />
        <br />
        9.5 5. 如有爭議，DFI Development (HK) Limited和Birdland (Hong Kong)
        Limited及Birdland (Macau) Limitada
        保留最終之決定權。須受其他Yuu條款和細則約束
      </p>
    </div>
    <div class="agreement">
      <p>KFC Club Membership Scheme Terms and Conditions (“Terms of Use”)</p>
      <h4>1. General</h4>
      <p>
        KFC Club Membership Scheme (“Membership Scheme” or “Membership”) is
        managed and operated by Jardine Matheson & Co Ltd – KFC Division and
        Birdland (Hong Kong) Limited (together referred to hereinafter as “KFC”,
        “we”, “our” or “us”).

        <br />
        <br />
        The Membership Scheme includes the accumulation / retention of Points
        Rewards (“Points Rewards”) in the unit of “Points”.
        <br />
        <br />
        Only customers who have completed the Membership Scheme registration
        process via KFC Hong Kong website, the KFC Hong Kong mobile application
        (the “KFC App”) or via the Storellet mobile application (see 1.4 below)
        (the “Storellet App”), both available for download from the App Store
        and Google Play Store, are eligible to become members of the Membership
        Scheme and enjoy Membership privileges (“Members”).
        <br />
        <br />
        The Storellet App is owned and operated by Crozz Limited. The Storellet
        App helps customers to organize loyalty mobile applications of their
        favourite restaurants (including the KFC App) by housing them into one
        single Storellet App. Use of the Storellet App will require registration
        as a member of Storellet and the member registration and use of the
        Storellet App are subject to Storellet’s own terms and conditions and
        privacy policy.
        <br />
        <br />
        Membership accounts can be accessed either via the KFC Hong Kong
        website, KFC App or the Storellet App. Any changes to Points Rewards
        balances and coupon records resulting from Members’ activities from
        within the Storellet App will be reflected in the Members’ account in
        the KFC Hong Kong website, and KFC App, and vice versa. In other words,
        whether a Member earns or redeems Points Rewards in the KFC Hong Kong
        website, KFC App or the Storellet App, all such activities relate to the
        same KFC Member’s account.
        <br />
        <br />
        Membership Scheme is open to individuals aged eighteen (18) or above. If
        you are twelve (12) years or older but under the age of eighteen (18)
        years and wish to become a Member, you will need your parent’s or legal
        guardian’s consent before completing the Membership Scheme registration.
        <br />
        <br />
        Members are required to verify their Membership by presenting to KFC
        staff for scanning their Member QR Code shown at Members’ profile page
        in the KFC App or KFC Hong Kong website (the “Member QR Code”). If
        members are using KFC Hong Kong website, they have to login before
        completing their transactions to verify their Membership.
        <br />
        <br />
        KFC Coupons (see 3.2 below), Selfie Coupons (see 3.3 below) and Groupbuy
        Coupons (see 3.4 below) are all identified by QR Codes (“Coupon QR
        Code”). To use these Coupons, Members must present for scanning by KFC
        staff at the time of ordering the applicable Coupon QR Code. When
        Members are using these Coupons on KFC Hong Kong website, they need to
        login and select the respective Coupon(s) before completing their
        transactions.
        <br />
        <br />
        Mis-use of the Membership Scheme, Points Rewards or its benefits may
        result in termination of Membership or withdrawal of privileges.
        <br />
        <br />
        KFC reserves the right to amend the Membership Scheme structure,
        privileges and other features, including these Terms of Use, or to
        terminate the Membership Scheme at any time. It is the responsibility of
        Members to keep themselves up-to-date with regard to matters relating to
        the Membership Scheme, including these Terms of Use. Use of Points
        Rewards/Membership privileges by Members will be deemed as Members’
        agreement to/acceptance of any changes to the Membership Scheme and/or
        these Terms of Use. KFC will not be liable for loss or damage resulting
        thereof.
        <br />
        <br />
        KFC does not accept any responsibility whatsoever for any network
        problems, system failures, phone connection problems and/or blockages
        due to third party applications which may result in the KFC App and its
        functions and services being affected or become unavailable.
        <br />
        <br />
        KFC reserves the right to suspend the KFC App or any parts of it for
        repairs, maintenance, upgrades or any other work KFC deems appropriate
        without prior notice.
        <br />
        <br />
        KFC’s records shall be conclusive. In case of disputes, KFC reserves the
        right of final decision on all Membership, Points Rewards, special
        offers/discounts and coupons related matters.
      </p>
      <h4>2. Membership Application/Registration</h4>
      <p>
        Customers can apply to become Members by completing the registration
        process via KFC Hong Kong website, the KFC App or the Storellet App (see
        1.3 and 1.4 above).
        <br />
        <br />
        Upon successful application, customers will receive a SMS verification
        code sent to their designated mobile phone number. Customers are
        required to enter the code where indicated to complete the Membership
        registration process.
        <br />
        <br />
        The eligibility for Membership is at the sole discretion of KFC. KFC may
        refuse any application of Membership to of any person and mayor
        terminate Membership at any time.
      </p>
      <h4>3. Membership Privileges</h4>
      <h5>3.1 Earning Points Rewards</h5>
      <p class="secondCon">
        a) To earn Points Rewards for any purchase, Members must present to KFC
        staff for scanning their Member QR Code before purchases are complete.
        If Members are using the KFC Hong Kong website, they must login before
        completing their transactions in order to earn points. Members providing
        only their mobile phone number and/or KFC Membership number will not be
        entitled to earn Points and there will be no re-issue of Points
        afterwards and/or after purchase have completed.
        <br />
        <br />
        b) Members can earn 1 Point for every HK$1 spent after discounts (if
        any) at any KFC restaurants/outlets, excluding decimal point. For
        example, HK$36.6 spent = 36 Points earned. Only purchases made
        personally by Members will earn Points Rewards.
        <br />
        <br />
        c) Only dine-in purchases at and takeaway purchases from KFC Hong Kong
        website and KFC restaurants/outlets in Hong Kong (excluding KFC
        restaurants/outlets at AsiaWorld-Expo, Racecourse and Hong Kong Stadium)
        will earn Points Rewards.
        <br />
        <br />
        d) Tips, discounts and special offers, gift redemptions, donations,
        delivery purchases via KFC Hong Kong website, birthday party and
        purchase of Groupbuy Coupons (see 3.4 below) will not earn Points
        Rewards.
        <br />
        <br />
        e) Members can earn Points Rewards up to a maximum of 3 times per day or
        10 times per week.
        <br />
        <br />
        f) Points Rewards can only be earned once per purchase each time.
        <br />
        <br />
        g) Points Rewards are valid until the next quarter end date followingfor
        periods of 12 months from the Membership registration date until the
        next quarter end date of the following year. Quarter end dates are 31
        Jan, 30 Apr, 31 Jul and 31 Oct each year. For example, The the Points
        Rewards of a Member who registered on 8 February 2015 will expire 30 Apr
        2016.
        <br />
        <br />
        h) Members are responsible for keeping track of their Points Rewards
        expiry dates. Points will be cleared automatically after their expiry
        date. In case of disputes, the records held by KFC shall be treated as
        conclusive evidence of balance of Points Rewards in the Members’
        account.
        <br />
        <br />
        i) KFC reserves the right to deduct Points from Membership accounts if
        KFC suspects that Points Rewards have been obtained via fraudulent or
        dishonest means.
      </p>
      <h5>3.2 Redemption of Points Rewards and KFC Coupons</h5>
      <p class="secondCon">
        a) Accumulation and redemption of Points Rewards are valid only during
        the Points Rewards’ validity period (see 3.1g above). Unused Points
        cannot be carried forward and will expire.
        <br />
        <br />
        b) Points Rewards are redeemed for KFC Coupons. To redeem, Members must
        login to their account via KFC Hong Kong website, the KFC App or
        Storellet App, go to “KFC Coupon”, click the KFC Coupon(s) desired and
        follow the steps to complete the redemption. Upon successful redemption
        the KFC Coupon(s) are saved into the KFC App’s “My Coupon”.
        <br />
        <br />
        c) Each KFC Coupon can only be used once per person.
        <br />
        <br />
        d) Redemption of Points Rewards is subject to the acceptance by KFC and
        to the availability of sufficient balance of Points in the Members’
        account. If there are insufficient Points, the redemption request will
        be refused.
        <br />
        <br />
        e) Points Rewards redemption cannot be changed or cancelled once
        confirmed successful and the applicable number of Points will be
        deducted from your Points Rewards account automatically.
        <br />
        <br />
        f) Each KFC Coupon is valid for a limited time only and expires on the
        date specified on the KFC Coupon. Unused or expired KFC Coupon will be
        forfeited and no refund or extension of validity period is possible.
        <br />
        <br />
        g) Use of KFC Coupons is subject to related terms and conditions shown
        on the KFC Coupons.
      </p>
      <h5>3.3 Selfie Coupons</h5>
      <p class="secondCon">
        a) To be eligible for a Selfie Coupon, the Member must, during Selfie
        Coupon promotion periods, login to their account via the KFC App or
        Storellet App, click on “Selfie Coupon” and follow the steps to complete
        the required task which will include taking and submitting a photograph
        (“Selfie”) and sharing the same on Facebook.
        <br />
        <br />b) Upon successful completion of the required task, the Selfie
        Coupon will be saved automatically into Member’s “My Coupon” in the KFC
        App.
        <br />
        <br />c) Limitation to the number of Selfie submissions by each Member
        will differ for each Selfie Coupon promotion period. Please refer to the
        specific terms and conditions of each Selfie Coupon promotion period in
        this regard.
        <br />
        <br />d) By submitting Selfies and/or participating in the Selfie Coupon
        promotion, Members automatically agree to be bound by these Terms of Use
        and to KFC’s Privacy Policy.
        <br />
        <br />e) By submitting Selfies and/or participating in the Selfie Coupon
        promotion, Members agrees and grants KFC an irrevocable perpetual right
        to reproduce, print, copy and publish the submitted Selfies and to use
        the Selfies for purposes of publicity or display without having to seek
        consents from Members or pay any fees to the Members.
        <br />
        <br />f) Members declare and warrant that they are the sole copyright
        owners of the submitted Selfies.
        <br />
        <br />g) Members declare and warrant that their submitted Selfies are
        original works produced by the Members themselves, have not infringed
        any intellectual property rights or any other rights of any third
        parties and have not been published or displayed in any form or in the
        media in or public before.
        <br />
        <br />h) KFC reserves the right to remove, without prior notice,
        Members’ submitted Selfies containing insulting, defamatory, indecent,
        abusive, misleading content or other inappropriate content.
        <br />
        <br />i) If Members’ submitted Selfies infringe or violate any third
        party’s rights, including but not limited to intellectual property
        rights, and/or any legislation, law or regulation, KFC shall expressly
        disclaim all liabilities.
        <br />
        <br />j) Members agree to indemnify and keep indemnified KFC for any
        losses, liabilities and costs which KFC may incur directly or indirectly
        as a result of any breach of these terms and conditions on the part of
        the Members in connection with the Selfies submitted inclusive but not
        limited to the infringement of intellectual property rights of third
        party.
        <br />
        <br />k) Each Selfie Coupon is valid for a limited time only and expires
        on the date specified on the Selfie Coupon. Unused or expired Selfie
        Coupons will be forfeited and no extension of validity period is
        possible.
        <br />
        <br />l) Use of Selfie Coupons is subject to related terms and
        conditions shown on the Selfie Coupons.
        <br />
        <br />m) KFC reserves the right to suspend the Selfie Coupon service or
        any parts of it for repairs, maintenance, upgrades or any other work KFC
        deems appropriate without prior notice.
      </p>
      <h5>3.4 Groupbuy Coupons</h5>
      <p class="secondCon">
        a) To purchase Groupbuy Coupons, Members must login to their account via
        the KFC App or the Storellet App, go to “KFC Groupbuy”, click the
        Groupbuy Coupon desired and follow steps to complete the purchase. Upon
        successful purchase, Groupbuy Coupons will be saved automatically into
        Members’ “My Coupon” in the KFC App.
        <br />
        <br />b) Refund or exchange is not possible once purchase for Groupbuy
        Coupon has completed.
        <br />
        <br />c) Groupbuy Coupons are applicable for dine-in and takeaway
        purchases only; Groupbuy Coupons cannot be used for delivery purchases.
        <br />
        <br />d) Each Groupbuy Coupon is valid for a limited time only and
        expires on the date specified on the Groupbuy Coupon.
        <br />
        <br />e) Use of Groupbuy Coupon is subject to related terms and
        conditions shown on the Groupbuy Coupon. Unused or expired Groupbuy
        Coupon will be forfeited and no refund or extension of validity period
        is possible.
        <br />
        <br />f) Groupbuy Coupon can only be used at the KFC restaurant/outlet
        selected at the time of purchase of the Groupbuy Coupon and change of
        the selected KFC restaurant/outlet is not possible once the Groupbuy
        Coupon purchase is complete.
        <br />
        <br />g) Groupbuy Coupon purchases will not earn Points Rewards.
        <br />
        <br />h) Groupbuy Coupons cannot be exchanged for cash.
        <br />
        <br />i) Groupbuy Coupons cannot be used in conjunction with other
        promotions, special offers or discounts.
        <br />
        <br />j) The food item combination of Groupbuy Coupons cannot be
        changed, except in circumstances where food item is sold out in which
        case the staff at the selected KFC restaurant/outlet maywill replace the
        sold out food item with another food item of the same or similar value.
        <br />
        <br />k) KFC reserves the right to suspend the Groupbuy Coupon service
        or any parts of it for repairs, maintenance, upgrades or any other work
        KFC deems appropriate without prior notice.
      </p>
      <h4>4. Termination of Membership</h4>
      <p class="secondCon">
        a) Members can terminate their Membership at any time by giving written
        notice to KFC. All unredeemed Points will be cancelled/forfeited.
        <br />
        <br />b) Any misconduct, fraud, misuse of points by any Member is found
        or failure to adhere to the Membership Scheme Terms of Use and/or any
        other related terms and conditions may result in immediate termination
        of Membership and cancellation of all Points Rewards with or without
        prior notice.
      </p>
      <h4>5. Privacy</h4>
      <p>
        Please refer to our Privacy Policy regarding our use and collection of
        personal information from customers and/or Members.
      </p>
      <h4>6. Miscellaneous</h4>
      <p class="secondCon">
        a) KFC reserves the right to amend these Terms of Use and any given
        information, services and resources available at the KFC restaurants /
        outlets, websites and online services including website at kfchk.com and
        the KFC App at any time without prior notice. A copy of the latest
        version of these Terms of Use is available at the KFC website and the
        KFC App. These Terms of Use will be governed by and construed in
        accordance with the laws of Hong Kong SAR and the parties agree to
        submit to the exclusive jurisdiction of the courts of Hong Kong SAR. If
        for any reason a court finds any provision or portion of these Terms of
        Use to be unenforceable, the remainder of these Terms of Use will
        continue in full force and effect. Any waiver of any provision of these
        Terms of Use by a party will be effective only if in writing and signed
        by a party.
        <br />
        <br />b) KFC shall have the right at any time and at its absolute
        discretion without giving any prior notice or reason to Members to
        terminate the operation of the Membership Scheme. Upon the termination
        of the operation of the Membership Scheme, all rights and privileges of
        Members shall cease and no claim or demand of whatsoever nature and
        howsoever arising shall be made or capable of being made by the Member
        against KFC in connection with the termination of the operation of the
        Membership Scheme.
        <br />
        <br />c) In case of discrepancies between the English and the Chinese
        versions of these Terms of Use, the English version shall prevail.
      </p>
      <h4>7. Home Delivery Purchases</h4>
      <p class="secondCon">
        7.1 Delivery time: 11:00am - 11:00pm, Monday to Sunday (including public
        holidays).
        <br />
        <br />7.2 Delivery to selected areas only. Please check according to the
        delivery address, ask our in-store staff or call our hotline (2180 0000)
        operators for details. Delivery service is received at ground floor of
        building or specific place when lift service is not available.
        <br />
        <br />7.3 Free delivery is applicable to orders ofover HK$90 or above
        and to one delivery address only. Order below HK$90 will be charged at
        HK$90 if delivery service is requested.
        <br />
        <br />7.4 Product sales depend on availability of individual store. Menu
        and price are subject to change without prior notice.
        <br />
        <br />7.5 Our delivery staff carries only a small amount of spare
        change.
        <br />
        <br />7.6 Not Accept UnionPay Card or JCB card for payment and no
        entitlement to any discount offers for these cards.
        <br />
        <br />7.7 To earn Point Rewards or redeem offers, KFC Crispy Fans Club
        [Note: is this different from KFC Membership Scheme?]members must sign
        in before transaction. Applicable for online ordering only, Membership
        Scheme Terms and Conditions apply.
        <br />
        <br />7.8 In case of any technical problems or intentional damage of the
        website that caused any disputes, Birdland(Hong Kong) Limited reserve
        the right of final decision.
        <br />
        <br />7.9 In case of any dispute, the decision of Birdland (Hong Kong)
        Limited shall be final.
      </p>
      <h4>8. Return of product or Refund</h4>
      <p>
        Pictures used on this website are for reference only. Customers cannot
        request a refund for the sole reason of inconsistency between pictures
        and delivered items.
        <br />
        <br />We aim to deliver every order accurately. In cases of disputes,
        customers shall call the stores that produced the items or our customer
        service hotline 21800000, or email us via the “Contact Us” page on our
        website, to inform us of any unsatisfactory delivery.
        <br />
        <br />If certain products are damaged when delivered, or if certain
        products already ordered are not delivered, or if certain products are
        misdelivered, KFC Hong Kong will refund equivalent value or redeliver
        the products at no extra cost.
        <br />
        <br />If certain products are delivered by mistake when customers didn’t
        order, KFC Hong Kong reserves the right to collect the misdelivered
        products at no extra cost.
        <br />
        <br />Customers who submit a refund request need to provide the
        following: refund reason, order number, phone number used for order,
        order date and payment method (credit card number or Alipay order number
        etc.). Confirmed refund requests will be processed within 6-8 weeks, and
        the refund amount will be returned to customer’s account used for
        payment (cheque will be used for cash payment).
      </p>
      <h4>9. Yuu points</h4>
      <p class="secondCon">
        9.1 KFC member and Yuu member can select to earn either KFC points or
        Yuu points for the purchase.
        <br />
        <br />9.2 Earning Yuu points and Yuu points redemption are not available
        for hotline ordering.
        <br />
        <br />9.3 Yuu points redemption is not available for online ordering.
        <br />
        <br />9.4 Yuu points can be redeemed at store only.
        <br />
        <br />9.5 In case of any dispute, DFI Development (HK) Limited and,
        Birdland (Hong Kong) Limited and Birdland (Macau) Limitada shall have
        the right of final decision. Other Yuu Terms & Conditions apply.
      </p>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
export default {
  name: "Agreement",
  data() {
    return {
      content: "",
    };
  },
  components: {
    Topbar,
  },
};
</script>
<style scoped>
.agreement {
  width: 100%;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.24rem;
  color: #030f14;
  text-align: justify !important;
  padding: 0.16rem;
  box-sizing: border-box;
  white-space: pre-wrap !important;
}
h4 {
  font-weight: 400;
  margin: 0.1rem 0 0.1rem 0.2rem;
}
h5 {
  font-weight: 400;
  margin: 0.1rem 0 0.1rem 0.4rem;
  font-size: 0.16rem;
}
.secondCon {
  margin-left: 0.4rem;
}
</style>